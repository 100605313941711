<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                  ref="searchConditionsDatePicker"
                  format="YYYY-MM-DD"
                  v-model="bsnDateValueModel"
                  type="lookup-condition"
                  :notClear="true"
                  :isGetDateInfo="true"
                  @change="handleSearchConditionsBsnDateChange"
                />
              </li>
              <li class="item text">
                <!-- TODO : getDayOfWeekCaptionColor의 첫번째 arg인 date값이 null이면 bsnCode와 dwCode로 색상 가져오도록 해놓았음 -->
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      null,
                      bsnDateInfo.bsnCode,
                      bsnDateInfo.dwCode
                    ),
                  }"
                  >{{ dayOfWeekCaption }}</span
                >) (<span
                  :style="{
                    color: commonCodesGetColorValue(
                      'BSN_CODE',
                      bsnDateInfo.bsnCode
                    ),
                  }"
                  >{{
                    commonCodesGetComName("BSN_CODE", bsnDateInfo.bsnCode)
                  }}</span
                >)
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              @click.native="handleSearchConditionsBsnDateChange"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition sub">
          <li class="field caddieName">
            <div class="title">캐디명</div>
            <ul class="content">
              <li class="item input">
                <input-text
                  ref="searchValue"
                  id="caddieTAReservationOptionsSearchValueInputTextBox"
                  v-model="findConditions.query"
                  placeholder="캐디명"
                  @keydown.enter="findCaddie"
                />
              </li>
            </ul>
            <ul class="content">
              <div class="lookup-lookup">
                <erp-button
                    buttonDiv="GET"
                    :is-icon-custom="true"
                    @click.native="findCaddie"
                >
                  찾기
                </erp-button>
              </div>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">캐디 목록</div>
              <div class="header-caption">
                [{{ numberWithCommas(caddieAttendTotalCount) }}건]
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="save keyColor">
                  <erp-button
                      buttonDiv="SAVE"
                      :is-shortcut-button="true"
                      :isIconCustom="true"
                      @click.native="handleSaveClick"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="create">
                  <erp-button
                      buttonDiv="SAVE"
                      :isIconCustom="true"
                      @click.native="handleCreateClick"
                  >
                    근태생성
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      buttonDiv="DELETE"
                      :isIconCustom="true"
                      @click.native="handleDeleteClick"
                  >
                    일괄삭제
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <template
              v-for="(
                { groupDivName, caddieAttendInfoList: dataSource },
                caddieGroupAttendIndex
              ) in gridDataSourceList"
            >
              <div
                class="body-box"
                v-bind:key="`caddieGroupAttendInfo-${caddieGroupAttendIndex}`"
              >
                <div class="body-header" style="border-top: none">
                  {{ groupDivName }}
                </div>
                <ejs-grid-wrapper
                  ref="caddieGroupAttendInfoGrids"
                  v-bind="caddieGroupAttendInfoGridProps"
                  :dataSource="dataSource"
                  @queryCellInfo="caddieGroupAttendInfoGridsQueryCellInfo"
                  @actionComplete="
                    caddieGroupAttendInfoGridsActionComplete(
                      $event,
                      caddieGroupAttendIndex
                    )
                  "
                  :isShowProgress="false"
                />
              </div>
            </template>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.caddieName .content .item.input {width: 90px;}

body .appContent .article-section.section-01 .section-body {display: flex; flex-direction: row; overflow-x: scroll; padding: 0 1px;}
body .appContent .article-section.section-01 .section-body:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-01 .body-box {float: left; width: 380px; height: 100%; margin: 0 -1px;}
body .appContent .article-section.section-01 .body-grid {height: calc(100% - 24px);}
body .appContent .article-section.section-01 .body-grid >>> .e-grid.e-lib {border-bottom-color: #e0e0e0;}
body .appContent .article-section.section-01 .body-grid >>> .e-grid.e-lib .e-gridheader {border-top-color: #e0e0e0;}
body .appContent .article-section.section-01 .body-header {box-sizing: border-box; height: 24px; padding: 3px 8px 2px 8px; border: 1px solid #ccc; border-bottom: none; background-color: #f9f9f9; color: #000; text-align: center;}
</style>

<script>
import Vue from "vue";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getFormattedDate,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from "@/utils/date";
import {
  createAttendInfo,
  deleteAttendInfo,
  getAttendInfoList,
  updateAttendInfo,
} from "@/api/caddieTARegistration";
import {
  commonCodesGetComName,
  commonCodesGetColorValue, commonCodesGetCommonCode,
} from '@/utils/commonCodes';
import { numberWithCommas } from "@/utils/number";
import {
  Edit,
  ForeignKey,
  Group,
  Resize,
  Selection,
} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import moment from "moment";
import _ from "lodash";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDate from "@/components/common/datetime/InputDate";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import gridVisitEjsDropdownlistEditTemplate
  from '@/components/common/gridTemplate/GridVisitEjsDropdownlistEditTemplate';

export default {
  name: "CaddieTARegistration",
  components: {
    InputText,
    InputDate,
    EjsGridWrapper,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  async created() {
    const queryBsnDate = this.$route.query.bsnDate;
    if (queryBsnDate && moment(queryBsnDate, "YYYY-MM-DD", true).isValid()) {
      this.searchConditions.bsnDate = queryBsnDate;
    }

    this.fetch();
  },
  data() {
    return {
      caddieAttendTotalCount: 0,
      searchConditions: {
        bsnDate: new Date(),
      },
      findConditions: {
        query: "",
        filter: 1,
      },
      displayFounds: false,
      founds: [][Symbol.iterator](),
      caddieGroupAttendList: [],
      bsnDateInfo: {
        bsnCode: null,
        dwCode: null,
      },
      filteredFoundIndexList: [],
      savedSearchValue: null,
      gridEjsDropdownlistEditTemplateEventBus: new Vue()
    };
  },
  methods: {
    getDayOfWeekCaptionColor,
    commonCodesGetComName,
    commonCodesGetColorValue,
    numberWithCommas,
    handleSearchConditionsBsnDateChange(args) {
      this.fetch();
      this.clearSearch(true);

      this.bsnDateInfo = args;
    },
    handleCreateClick() {
      this.createAttendInfo();
    },
    handleSaveClick() {
      const changedRecords = _.flatten(
        this.$refs["caddieGroupAttendInfoGrids"].map((ref) => {
          ref.saveCell();

          const { changedRecords } = ref.getBatchChanges();

          return changedRecords;
        })
      );

      if (changedRecords.length === 0) {
        this.errorToast("변경사항이 없습니다");
        return;
      }

      this.updateAttendInfo();
    },
    async handleDeleteClick() {
      if (!(await this.confirm("정말로 삭제하시겠습니까?"))) {
        return;
      }

      await this.deleteAttendInfo();
    },
    findCaddie: function () {
      const searchValue = this.findConditions.query;
      if (!searchValue) {
        this.infoToast(this.$t("main.popupMessage.noSearchValue"));
      } else {
        this.findAndSelectRowProcess(searchValue);
      }
    },
    getCaddieFindIndex: function (trimedSearchValue) {
      const groupLength = this.gridDataSourceList.length;

      let result = {
        groupIdx: 0,
        findIdx: 0,
      };

      for (let i = 0; i < groupLength; i++) {
        this.$refs.caddieGroupAttendInfoGrids[i].clearSelection();
      }

      for (let i = 0; i < groupLength; i++) {
        const filteredCaddieGroupAttendList = this.filteredFoundIndexList[i]
          ? this.gridDataSourceList[i].caddieAttendInfoList.filter(
              (item, index) => this.filteredFoundIndexList[i].indexOf(index) < 0
            )
          : this.gridDataSourceList[i].caddieAttendInfoList;

        const findItem = filteredCaddieGroupAttendList.find(
          (item) =>
            item.caddieName && item.caddieName.includes(trimedSearchValue)
        );

        const findIdx = this.gridDataSourceList[
          i
        ].caddieAttendInfoList.findIndex((item) => item === findItem);

        result.groupIdx = i;
        result.findIdx = findIdx;

        if (result.findIdx >= 0) {
          if (!this.filteredFoundIndexList[i]) {
            this.filteredFoundIndexList[i] = [];
          }
          this.filteredFoundIndexList[i].push(findIdx);

          return result;
        }
      }

      if (result.findIdx === -1) {
        for (let i = 0; i < this.filteredFoundIndexList.length; i++) {
          if (this.filteredFoundIndexList[i]) {
            result.groupIdx = i;
            result.findIdx = this.filteredFoundIndexList[i][0];

            this.filteredFoundIndexList = [];
            this.filteredFoundIndexList[i] = [result.findIdx];
          }
        }
      }

      return result;
    },
    clearSearch: function (claerSavedSearchValue = false) {
      if (claerSavedSearchValue) {
        this.savedSearchValue = null;
      }
      this.filteredFoundIndexList = [];

      for (let i = 0; i < this.gridDataSourceList.length; i++) {
        this.$refs.caddieGroupAttendInfoGrids[i].clearSelection();
      }
    },
    findAndSelectRowProcess: function (searchValue) {
      if (Array.isArray(this.gridDataSourceList)) {
        if (searchValue) {
          const trimedSearchValue = searchValue.trim();

          if (this.savedSearchValue !== trimedSearchValue) {
            this.savedSearchValue = trimedSearchValue;
            this.clearSearch();
          }

          const { groupIdx, findIdx } = this.getCaddieFindIndex(
            trimedSearchValue
          );

          if (findIdx === -1) {
            this.errorToast(
              trimedSearchValue + "으로 검색된 캐디 정보가 없습니다"
            );
            document
              .getElementById(
                "caddieTAReservationOptionsSearchValueInputTextBox"
              )
              .select();
          } else {
            this.caddieGroupRowSelectProcess(groupIdx, findIdx);
          }
        } else {
          if (
            this.gridDataSourceList.length > 0 &&
            Array.isArray(this.gridDataSourceList[0].caddieAttendInfoList) &&
            this.gridDataSourceList[0].caddieAttendInfoList.length > 0
          ) {
            this.caddieGroupRowSelectProcess(0, 0);
          }
        }
      }

      setTimeout(() => {
        this.$refs.searchValue.focusIn();
      }, 1);
    },
    caddieGroupRowSelectProcess: function (groupIdx, findIdx) {
      this.$refs.caddieGroupAttendInfoGrids[groupIdx].selectRow(findIdx);
    },
    async fetch() {
      if (this.bsnDateValueModel) {
        const { data } = await GolfErpAPI.fetchCalenderInfo(
          this.bsnDateValueModel,
          false
        );
        this.bsnDateInfo.bsnCode = data.bsnCode;
        this.bsnDateInfo.dwCode = data.dwCode;
      }

      try {
        const {
          value: { caddieGroupAttendList },
        } = await getAttendInfoList(
          moment(this.searchConditions.bsnDate).format("YYYY-MM-DD")
        );

        this.caddieGroupAttendList = caddieGroupAttendList;

        this.caddieAttendTotalCount = 0;
        for (let a = 0; a < this.gridDataSourceList.length; a++) {
          this.caddieAttendTotalCount += this.gridDataSourceList[
            a
          ].caddieAttendInfoList.length;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async createAttendInfo() {
      try {
        await createAttendInfo(
          moment(this.searchConditions.bsnDate).format("YYYY-MM-DD")
        );
        this.fetch();
        this.infoToast("생성되었습니다");
      } catch (e) {
        console.error(e);
      }
    },
    async updateAttendInfo() {
      const attendDate = moment(this.searchConditions.bsnDate).format(
        "YYYY-MM-DD"
      );
      const changedRecords = _.flatten(
        this.$refs["caddieGroupAttendInfoGrids"].map((ref) => {
          const { changedRecords } = ref.getBatchChanges();

          return changedRecords.map((record) => {
            return {
              ...record,
              attendDate,
            };
          });
        })
      );

      try {
        await updateAttendInfo(changedRecords);
        this.fetch();
        this.infoToast(this.$t("main.popupMessage.saved"));
      } catch (e) {
        console.error(e);
      }
    },
    async deleteAttendInfo() {
      try {
        await deleteAttendInfo(
          moment(this.searchConditions.bsnDate).format("YYYY-MM-DD")
        );
        this.fetch();
        this.infoToast(this.$t("main.popupMessage.deleted"));
      } catch (e) {
        console.error(e);
      }
    },
    caddieGroupAttendInfoGridsQueryCellInfo(args) {
      // editable cell
      if (args.column.field === "caddieAttendDiv") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
        args.cell.style.backgroundColor = commonCodesGetColorValue(
          "CADDIE_ATTEND_DIV",
          args.data.caddieAttendDiv
        );
      }
    },
    caddieGroupAttendInfoGridsActionComplete(args) {
      if (args.requestType === "filtering") {
        this.caddieAttendTotalCount = 0;
        for (let a = 0; a < this.gridDataSourceList.length; a++) {
          this.caddieAttendTotalCount += this.$refs.caddieGroupAttendInfoGrids[
            a
          ].getCurrentViewRecords().length;
        }
      }
    },
  },
  computed: {
    dayOfWeekCaptionStyle() {
      return {
        color: getDayOfWeekCaptionColor(this.searchConditions.bsnDate),
      };
    },
    dayOfWeekCaption() {
      return getDayOfWeekCaption(this.searchConditions.bsnDate);
    },
    weekdayWeekendCaptionStyle() {
      return {
        color: getWeekdayWeekendCaptionColor(this.searchConditions.bsnDate),
      };
    },
    weekdayWeekendCaption() {
      return getWeekdayWeekendCaption(this.searchConditions.bsnDate);
    },
    caddieGroupAttendInfoGridProps() {
      return {
        provides: [Edit, Resize, ForeignKey, Group, Selection],
        isSelectedRowRetain: false,
        isNOColumnDisplay: false,
        columns: [
          {
            allowEditing: false,
            field: "argmtSno",
            headerText: "배치번호",
            textAlign: "Center",
            type: "number",
            format: "N",
            visible: this.findConditions.filter === 0,
            width: 80,
          },
          {
            allowEditing: false,
            field: "caddieNo",
            headerText: "캐디번호",
            textAlign: "Center",
            type: "string",
            visible: this.findConditions.filter === 1,
            width: 80,
          },
          {
            allowEditing: false,
            allowSorting: false,
            field: "caddieName",
            headerText: "캐디명",
            textAlign: "Center",
            type: "string",
            width: 120,
          },
          {
            allowEditing: true,
            allowSorting: false,
            customAttributes: {
              class: this.$t("className.grid.requiredInputHeader"), // required field
            },
            groupCode: "CADDIE_ATTEND_DIV",
            field: "caddieAttendDiv",
            isCommonCodeField: true,
            editType: "dropdownedit",
            headerText: "근태",
            textAlign: "Center",
            type: "string",
            editTemplate: () =>
              gridVisitEjsDropdownlistEditTemplate(
                  "caddieAttendDiv",
                commonCodesGetCommonCode("CADDIE_ATTEND_DIV"),
                this.$refs.caddieGroupAttendInfoGrids,
                this.gridEjsDropdownlistEditTemplateEventBus,
                true
              ),
            width: 120,
          },
        ],
        editSettings: {
          allowAdding: false,
          allowDeleting: false,
          allowEditing: true,
          mode: "Batch",
          showConfirmDialog: false,
        },
        selectionSettings: {
          enableToggle: true,
          type: "Single",
        },
      };
    },
    gridDataSourceList() {
      return this.caddieGroupAttendList.map(
        ({ groupDivName, caddieAttendInfoList }) => {
          return {
            groupDivName,
            caddieAttendInfoList: _.sortBy(caddieAttendInfoList, [
              (caddieAttendInfo) =>
                this.findConditions.filter === 0
                  ? caddieAttendInfo["argmtSno"]
                  : caddieAttendInfo["caddieNo"],
            ]),
          };
        }
      );
    },
    bsnDateValueModel: {
      get() {
        return getFormattedDate(this.searchConditions.bsnDate);
      },
      set(bsnDateValueModel) {
        this.searchConditions.bsnDate = moment(bsnDateValueModel).toDate();
      },
    },
  },
};
</script>
