import request from "@/utils/request";

/**
 * @return Promise
 */
export function getAttendInfoList(attendDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/attend/info/list",
    method: "get",
    params: {
      attendDate,
    },
  });
}

/**
 * @return Promise
 */
export function createAttendInfo(attendDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/game_management/attend/info/create/${attendDate}`,
    method: "post",
    data: {},
  });
}

/**
 * @return Promise
 */
export function updateAttendInfo(changedRecords) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/attend/info/detail",
    method: "put",
    data: {
      changedRecords,
    },
  });
}

/**
 * @return Promise
 */
export function deleteAttendInfo(attendDate) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: `/game_management/attend/info/${attendDate}`,
    method: "delete",
  });
}
